import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mr-10 ml-10 mb-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_form, {
      ref: "params",
      model: _ctx.params,
      rules: _ctx.paramsRules,
      "label-width": "84px",
      class: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form_item, {
          label: "留言标题",
          prop: "title"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.params.title,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.params.title) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, { label: "手机号" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.params.tel,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.params.tel) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, { label: "邮箱" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.params.email,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.params.email) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, { label: "留言内容" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              type: "textarea",
              rows: 3,
              placeholder: "请输入内容",
              modelValue: _ctx.params.content,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.params.content) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, { label: "发布时间" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_date_picker, {
              modelValue: _ctx.params.createdAt,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.params.createdAt) = $event)),
              type: "datetime",
              placeholder: "选择日期时间"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              type: "primary",
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.submit('params')))
            }, {
              default: _withCtx(() => [
                _createTextVNode("保存")
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model", "rules"])
  ]))
}