
// import { update, detail } from "../../api/message.js";
import { defineComponent } from 'vue';
import { client } from '@/client';
import { ElMessage } from 'element-plus';

export default defineComponent({
  name: "message-edit",

  data: () => {
    return {
      params: {
        id: 0,
        title: "",
        tel: "",
        email: "",
        content: "",
        createdAt: new Date(),
      },

      paramsRules: {
        //校验规则
        name: [
          { required: true, message: "请输入栏目名称", trigger: "blur" },
          {
            min: 2,
            max: 50,
            message: "名称长度在 2 到 50 个字符之间",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {},
  async created() {
    let id = this.$route.params.id as string;
    this.params.id = parseInt(id);
    await this.detail();
  },
  methods: {
    // 文章详情
    async detail() {
      try {
        let res = await client.callApi("message/Detail",{id:this.params.id});
        if (res.isSucc) {
          let data = res.res;
          this.params = {
            ...data
          };
        }
      } catch (error) {
        console.error(error);
      }
    },

    handleAttr(e: any) {
      console.log("e-->", e);
    },

    handleSubCid(e: any) {
      console.log("e-->", e);
    },

    //更新
    async update() {
      try {
        let res = await client.callApi("message/Edit",{
id: this.params.id,
title: this.params.title,
tel: this.params.tel,
email: this.params.email,
content: this.params.content,
createdAt: this.params.createdAt
});
        if (res.isSucc) {
          ElMessage({
            message: "更新成功^_^",
            type: "success",
          });
          this.$router.go(-1);
        }
      } catch (error) {
        console.log(error);
      }
    },

    submit(formName: string) {
      let ref:any = this.$refs[formName];
      ref.validate((valid: any) => {
        if (valid) {
          this.update();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
});
